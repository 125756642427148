<!-- eslint-disable vue/no-unused-components -->
<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class="overflow-hidden">
    <Hero :visible.sync="visible" class="pt-28">
      <XrayWrapperDealer />
    </Hero>
    <Hero
      v-if="0"
      :visible.sync="visible"
      class="pb-12 pb-sm-20 pb-md-16 pb-lg-0 pt-40"
    >
      <b-container>
        <b-row>
          <b-col lg="12" class="text-center">
            <h1
              class="
                    text-6xl text-md-7xl text-lg-8xl text-white
                    font-weight-bold font-realist
                    tracking-tight
                    leading-none
                    mb-12
                  "
            >
              Are you maximizing your used <br class="d-none d-lg-inline" />
              car inventory?
            </h1>
            <p
              class="
                    font-weight-medium
                    text-md-2xl text-xl
                    leading-tight
                    text-blue-100
                  "
            >
              Our end-to-end solution for dealers, powered by the
              <br class="d-none d-lg-inline" />
              industry standard condition report.
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid="lg" class="px-0">
        <b-row>
          <b-col lg="10" offset-lg="1">
            <div class="text-center mt-16">
              <XrayWrapper bg-color="#fff" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <div class="mt-16 mt-lg-24">
        <b-container class="anchor-target" id="wholesale">
          <b-row>
            <b-col
              lg="8"
              offset-lg="2"
              offset-xl="0"
              xl="6"
              order="2"
              class="d-flex align-items-center position-relative"
            >
              <div class="right-n48 position-xl-absolute mb-12 mb-xl-0">
                <img class="w-100" src="@/assets/img/transparency.png" />
              </div>
            </b-col>
            <b-col xl="6" order="1" class="d-flex align-items-center">
              <div>
                <div class="mb-8">
                  <h2
                    class="
                          font-weight-black
                          tracking-tight
                          text-3xl text-md-6xl text-xl-7xl
                          font-realist
                          leading-none
                          text-blue-500
                        "
                  >
                    Transparency Yields Higher Gross Profits
                  </h2>
                </div>
                <div>
                  <p class="text-xl text-md-2xl mb-0">
                    <em class="font-weight-bold text-orange-500">
                      Shoppers crave transparency and are willing to spend more
                      for peace of mind.
                    </em>
                    Providing your customers an unbiased, 3rd party inspection
                    report on a used vehicle builds credibility and
                    transparency.
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="mt-24 mt-lg-32">
        <b-container fluid="sm">
          <Assess />
        </b-container>
      </div>

      <div class="mt-24 mt-lg-56">
        <b-container>
          <b-row>
            <b-col lg="6">
              <div class="mb-8 mb-md-12 mb-lg-0">
                <img
                  class="img-fluid"
                  :src="require('@/assets/img/true360-report-spread.png')"
                />
                <!-- <Report /> -->
              </div>
            </b-col>
            <b-col lg="6" class="d-flex align-items-center">
              <div>
                <div class="mb-8">
                  <h2
                    class="
                          font-weight-black
                          tracking-tight
                          text-3xl text-md-6xl text-xl-7xl
                          font-realist
                          leading-none
                          text-blue-500
                        "
                  >
                    Report Enhancement
                  </h2>
                </div>
                <p class="text-xl text-md-2xl mb-0">
                  The results of the inspection are populated into a report that
                  can be shared with your customers. The report is also linked
                  to Carfax and AutoCheck .
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="mt-8 mt-lg-24">
        <b-container>
          <b-row>
            <b-col lg="6" class="d-flex align-items-start">
              <div>
                <div class="mb-8">
                  <h2
                    class="
                          font-weight-black
                          tracking-tight
                          text-2xl text-md-4xl
                          font-realist
                          leading-none
                          text-gray-500
                        "
                  >
                    Neutralize Accident History
                  </h2>
                </div>
                <p class="text-xl mb-0">
                  True360 reports revealed that 70% of accident vehicles with a
                  reported accident history only sustained cosmetic damage!
                  True360 is the only third party inspection and report that
                  reveals the vehicle’s cosmetic and structural condition. The
                  inspection reports are linked with Carfax and AutoCheck and
                  can neutralize the sting of accident vehicles.
                </p>
              </div>
            </b-col>
            <b-col lg="6" class="mt-lg-0 mt-8 d-flex align-items-start">
              <div>
                <div class="mb-8">
                  <h3
                    class="
                          font-weight-black
                          tracking-tight
                          text-2xl text-md-4xl
                          font-realist
                          leading-none                          
                          text-gray-500
                        "
                  >
                    Showcase Clean&nbsp;Cars
                  </h3>
                </div>
                <p class="text-xl mb-0">
                  Our inspectors can perform inspections on non-accident
                  vehicles to showcase a vehicle's cosmetic integrity.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="mt-32 mt-lg-48 pt-16 pt-lg-32 skew-top-ltr bg-gray-100 ">
        <b-container>
          <b-row class="mb-12 align-items-center">
            <b-col md="10" offset-md="1" lg="6" offset-lg="0">
              <div class="h-100 pr-lg-8 mb-12 mb-lg-0">
                <VDPCardWrapper style="height: 416px" />
              </div>
            </b-col>
            <b-col lg="6">
              <div>
                <div class="mb-8">
                  <h3
                    class="
                          font-weight-black
                          tracking-tight
                          text-3xl text-md-6xl text-xl-7xl
                          font-realist
                          leading-none
                          text-blue-500
                        "
                  >
                    Supercharged Digital&nbsp;Retail
                  </h3>
                </div>
                <p class="text-xl text-md-2xl mb-0">
                  Adding the True360 weblink to your VDP or SRP means customers
                  can view the inspection results directly from your website as
                  well as the vehicle history report, converting online shoppers
                  into&nbsp;buyers!
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col
              v-for="(m, idx) in metrics"
              cols="12"
              lg="4"
              class="mx-auto"
              :key="idx"
            >
              <div class="d-flex mb-2 mb-lg-5 align-items-center">
                <div class="mr-4 text-green-500">
                  <font-awesome-icon
                    size="4x"
                    icon="arrow-alt-circle-up"
                    style="max-width: 48px"
                  />
                </div>
                <div>
                  <p class="text-xl font-weight-black mb-0 leading-tight">
                    {{ m.text }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container id="retail" class="anchor-target mt-24 mt-lg-48">
          <b-row>
            <b-col lg="6" xl="7" class="d-flex align-items-center">
              <div>
                <div class="mb-8">
                  <h2
                    class="
                          font-weight-black
                          tracking-tight
                          text-3xl text-md-6xl text-xl-7xl
                          font-realist
                          leading-none
                          text-blue-500
                        "
                  >
                    Increase Transparency <br class="d-none d-sm-inline" /><span
                      class="stylistic-alt"
                      >&</span
                    >
                    Your Margins
                  </h2>
                </div>
                <div>
                  <p class="text-xl text-md-2xl mb-16 mb-lg-0 pr-16">
                    Help your customers make informed purchasing decisions while
                    selling in complete confidence.
                  </p>
                </div>
              </div>
            </b-col>
            <b-col lg="6" xl="5" class="d-flex align-items-center">
              <div class="bg-white rounded overflow-hidden shadow w-100">
                <b-table
                  bordered
                  :fields="retail.fields"
                  :items="retail.items"
                  tdClass="mt-5"
                  class="mb-0 text-lg align-middle services-table border-0"
                >
                  <template #cell(ourServices)="data">
                    <div class="mt-1">
                      {{ data.value }}
                    </div>
                  </template>
                  <template #cell(true360)="data">
                    <div class="text-green">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="check-circle"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="svg-inline--fa fa-check-circle fa-w-16 fa-2x"
                      >
                        <path
                          fill="#f26522"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                          class=""
                        ></path>
                      </svg>
                    </div>
                    <span v-html="data.value"></span>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-container class="mt-24 mt-lg-72 pb-24 pb-lg-32">
          <b-row>
            <b-col
              cols="12"
              lg="8"
              offset-lg="2"
              xl="10"
              offset-xl="1"
              class="text-center"
            >
              <h2
                class="
                      text-3xl text-md-6xl text-xl-7xl
                      font-realist font-weight-black
                      text-white
                      tracking-tight
                      leading-none
                      text-blue-500
                      mb-12
                    "
              >
                Reach Out to a Representative to Get Started
              </h2>
              <div>
                <b-button
                  variant="primary"
                  size="lg"
                  class="
                        btn-green-500
                        border-0
                        rounded-lg
                        py-4
                        font-weight-bold
                        d-block d-md-inline-block
                      "
                  :to="{ name: 'LearnMore' }"
                >
                  <span
                    class="
                          font-weight-bold font-realist-normal
                          text-xl text-md-2xl
                          px-12
                          text-white text-uppercase
                          tracking-wider
                        "
                    >Get In Touch</span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </main>
  </div>
</template>

<script>
import VDPCardWrapper from "@/components/vdpCard/vdpCardWrapper";
import Hero from "@/components/global/Hero";
import XrayWrapperDealer from "@/components/XrayWrapperDealer";

import Assess from "@/components/Assess";
// import Report from "@/components/Report";

export default {
  name: "DealersPage",
  components: {
    VDPCardWrapper,
    Hero,
    Assess,
    // Report,
    XrayWrapperDealer
  },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      visible: true,
      steps: [
        {
          title: "Carfax and Autocheck Integration",
          description:
            "A perfect solution for any vehicle with an accident report that doesn't reflect a vehicle's actual cash value.",
          icon: {
            type: "svg",
            value: require("@/assets/svg/ScalableSolutions_carfax.svg")
          }
        },
        {
          title: "Remove Uncertainty",
          description:
            "An easy way to digitally attract attention to aging inventory and eliminate any uncertainly.",
          icon: {
            type: "svg",
            value: require("@/assets/svg/ScalableSolutions_history.svg")
          }
        },
        {
          title: "Full Transparency",
          description:
            "Use on all of your pre-owned inventory to provide customers an extra layer of transparency for every used vehicle on your lot. ",
          icon: {
            type: "svg",
            value: require("@/assets/svg/ScalableSolutions_transparency.svg")
          }
        }
      ],
      metrics: [
        {
          text: "Increase user time on Dealer VDP and SRP",
          percent: "300%"
        },
        {
          text: "Increase in confirmed appointments",
          percent: "92%"
        },
        {
          text: "Increase of appointments converting to sale",
          percent: "64%"
        },
        {
          text: "Save money on reconditioning costs",
          percent: "64%"
        },
        {
          text: "Sell your vehicles for higher gross profits",
          percent: "64%"
        }
      ],
      retail: {
        fields: [
          {
            key: "ourServices",
            label: "Our Services",
            thClass:
              "border-top-0 text-uppercase text-white bg-primary py-4 text-lg border-gray-700 border-bottom-0"
          },
          {
            key: "true360",
            label: "True360",
            thClass:
              "border-top-0 text-uppercase text-white  bg-primary py-4 text-lg border-gray-700 border-bottom-0"
          }
        ],
        items: [
          {
            ourServices: "Basic CR (mileage, etc.)",
            true360: ""
          },
          {
            ourServices: "Photos",
            true360: ""
          },
          {
            ourServices: "Paint Meter Scan",
            true360: ""
          },
          {
            ourServices: "Certified Structural Inspection",
            true360: ""
          },
          {
            ourServices: "Carfax and Autocheck Enhancement",
            true360: ""
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.services-table {
  th,
  td {
    &:first-child {
      border-left: 0 !important;
    }

    &:last-child {
      border-right: 0 !important;
    }

    &:not(:first-child) {
      text-align: center;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 0;
    }
  }
}
</style>
