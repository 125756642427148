<template>
  <div class="row">
    <div class="col-12">
      <div class="mb-lg-16">
        <div class="text-center">
          <h2
            class="
              font-weight-black
              tracking-tight
              text-3xl text-md-6xl text-xl-7xl
              font-realist
              leading-none
              text-blue-500
            "
          >
            How We Assess Condition
          </h2>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mb-5 mb-lg-0">
      <exteriorAnimation />
    </div>
    <div class="col-12 col-lg-6">
      <div class="col col-lg-12 mb-lg-4">
        <div class="text-center position-relative mb-4">
          <div>
            <div
              class="
                text-primary
                font-weight-bold
                text-xl text-md-2xl text-lg-3xl
                mb-0
                text-nowrap
                leading-md-tight
              "
            >
              Exterior Inspection
            </div>
            <div class="text-gray-500 text-md-xl text-xl-2xl leading-md-tight">
              <span>Cosmetic &amp; Structural Assessment</span>
            </div>
          </div>
        </div>
      </div>

      <b-row class="justify-content-center">
        <b-col
          md="9"
          lg="6"
          class="mb-8 d-flex align-items-center align-items-lg-start"
        >
          <div class="w-12 h-12 h-lg-8 w-lg-8 mr-4 flex-shrink-0">
            <inline-svg :src="require(`@/assets/svg/assess_paint.svg`)" />
          </div>
          <div>
            <span class="text-md text-md-xl text-lg-md tracking-tight"
              >Thorough paint meter reading to identify irregularities in paint
              quality.</span
            >
          </div>
        </b-col>
        <b-col
          md="9"
          lg="6"
          class="mb-8 d-flex align-items-center align-items-lg-start"
        >
          <div class="w-12 h-12 h-lg-8 w-lg-8 mr-4 flex-shrink-0">
            <inline-svg :src="require(`@/assets/svg/assess_speedometer.svg`)" />
          </div>
          <div class="pr-md-4">
            <span class="text-md text-md-xl text-lg-md tracking-tight"
              >Complete hands-on evaluation of the exterior surfaces.</span
            >
          </div>
        </b-col>
        <b-col
          md="9"
          lg="6"
          class="mb-8 d-flex align-items-center align-items-lg-start"
        >
          <div class="w-12 h-12 h-lg-8 w-lg-8 mr-4 flex-shrink-0">
            <inline-svg :src="require(`@/assets/svg/assess_checklist.svg`)" />
          </div>
          <div class="pr-md-4">
            <span class="text-md text-md-xl text-lg-md tracking-tight"
              >Indicates where repairs have been made in an easy-to-understand
              report.
            </span>
          </div>
        </b-col>
        <b-col
          md="9"
          lg="6"
          class="mb-8 d-flex align-items-center align-items-lg-start"
        >
          <div class="w-12 h-12 h-lg-8 w-lg-8 mr-4 flex-shrink-0">
            <inline-svg :src="require(`@/assets/svg/assess_tools.svg`)" />
          </div>
          <div class="pr-md-4">
            <span class="text-md text-md-xl text-lg-md tracking-tight"
              >Topside inspection of the unibody, structure, and any visible
              topside frame components to identify hidden damage.
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

import exteriorAnimation from "@/components/slideAnimations/exterior";

export default {
  name: "Assess",
  components: {
    InlineSvg,
    exteriorAnimation
  },
  data() {
    return {
      steps: [
        {
          title: "Exterior",
          description: "Cosmetic & wear assessment",
          icon: "car"
        }
      ]
    };
  },
  methods: {
    getHeight(el) {
      setTimeout(() => {
        this.wrapperHeight = el.clientHeight;
      }, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.icon {
  flex-grow: 1;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin-right: 0.15rem;
    // margin-top: -0.1rem;
  }
  @media (min-width: 769px) {
    max-width: 2rem;
    max-height: 2rem;
    // margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
