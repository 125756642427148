<template>
  <div class="position-relative">
    <div class="position-absolute bottom-0 left-0">
      <VDPCard :details="car1" />
    </div>
    <div class="position-absolute top-0 right-0">
      <VDPCard :details="car2" :active="true" />
    </div>
  </div>
</template>

<script>
import VDPCard from "@/components/vdpCard/vdpCard";
import chevy from "@/assets/img/chevy.png";
import jaguar from "@/assets/img/jaguar.png";

export default {
  name: "VDPCardWrapper",
  components: {
    VDPCard
  },
  data() {
    return {
      car1: {
        title: "2018 Chevrolet Equinox",
        image: chevy
      },
      car2: {
        title: "2019 Jaguar E-Pace",
        image: jaguar
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
