<template>
  <div class="d-flex justify-content-center top-8 position-relative">
    <div class="position-relative">
      <div class="position-relative top-0 left-0 h-100 w-100 z-2">
        <inline-svg
          :src="require(`@/assets/img/home/trans-exterior-scan.svg`)"
          class="top"
        />
      </div>
      <img
        class="base max-w-100 max-h-100 position-absolute h-100 w-100 object-contain top-0 left-0 z-1"
        src="@/assets/img/home/trans-exterior.png"
      />
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
  name: "exterior",
  props: {},
  components: {
    InlineSvg
  },
  created: function() {
    document.querySelectorAll("animate").forEach(element => {
      element.beginElement();
    });
  }
};
</script>

<style lang="scss" scoped>
.top {
  height: 334px;
  width: auto;

  @media (min-width: 1200px) {
    height: 500px;
  }

  @media (max-width: 425px) {
    height: 224px;
  }
}

.base {
  height: 334px;
  width: auto;

  @media (min-width: 1200px) {
    height: 500px;
  }

  @media (max-width: 425px) {
    height: 224px;
  }
}
</style>
