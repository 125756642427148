<template>
  <div class="position-relative">
    <div
      :class="active ? 'shadow-lg active' : 'bg-white shadow border'"
      class="rounded-lg overflow-hidden"
      style="width: 300px"
    >
      <div class="embed-responsive embed-responsive-16by9">
        <div class="embed-responsive-item">
          <img
            @load="imageReady = true"
            class="h-100 w-100 object-cover transition-fast"
            :src="details.image"
            alt=""
            :style="{ opacity: imageReady ? '1' : '0' }"
          />
        </div>
      </div>
      <div class="pt-3 pb-6 px-5">
        <div>
          <span
            :class="active ? 'text-white' : ''"
            class="font-realist font-weight-bold text-xl"
          >
            {{ details.title }}
          </span>
        </div>
        <b-form-row :class="active ? 'opacity-50' : ''">
          <b-col
            v-for="(icon, idx) in icons"
            :key="idx"
            cols="6"
            class="d-flex mt-2 align-items-center"
          >
            <div
              :class="active ? 'text-white' : 'text-gray-400'"
              class="
                w-5
                h-5
                rounded
                flex-shrink-0
                mr-3
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <inline-svg
                class="flex-grow-1"
                :src="require(`@/assets/svg/${icon}.svg`)"
              />
            </div>
            <div class="flex-grow-1">
              <div
                :class="active ? 'bg-white' : 'bg-gray-400'"
                class="h-2 rounded"
                :style="`width: ${getWidth()}%`"
              ></div>
            </div>
          </b-col>
        </b-form-row>
      </div>
    </div>
    <div v-if="active" class="position-absolute right-n4" style="top: 42%">
      <img
        class="h-auto w-32 shadow-sm"
        src="@/assets/img/true-360-vdp-badge.png"
        alt="True360 VDP Badge"
      />
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "VDPCard",
  props: {
    details: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean
    }
  },
  components: {
    InlineSvg
  },
  data() {
    return {
      imageReady: false,
      blue: "#2498EB",
      icons: ["engine", "swatch", "gear", "seat", "road"]
    };
  },
  methods: {
    getWidth() {
      let min = 60;
      let max = 90;
      return Math.floor(Math.random() * (max + 1 - min)) + min;
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  background: #a1d8ff;
  background: linear-gradient(
    165deg,
    rgba(#a1d8ff, 1) 0%,
    rgba(#36a0ec, 1) 100%
  );
  // border-color: rgba(#36a0ec, 0.6) !important;
  box-shadow: 0 8px 48px rgba(#36a0ec, 0.9) !important;
}
</style>
